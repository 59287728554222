<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="black"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pa-5>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 text-left>
            <span style="color: #000; font-family: poppinsbold; font-size: 25px"
              >Exam Rules</span
            >
          </v-flex>
        </v-layout>
        <v-layout wrap py-10>
          <v-flex xs12 text-left pb-5>
            <span
              style="color: #000; font-family: poppinsmedium; font-size: 18px"
              >Rules</span
            >
          </v-flex>
          <v-flex xs12 text-left>
            <div id="app">
              <vue-editor v-model="rules.examRule"></vue-editor>
            </div>
          </v-flex>
        </v-layout>
        <v-layout wrap py-5 justify-center>
          <v-flex xs12 sm6>
            <v-btn tile color="#3CB043" block dark @click="update()"
              ><span style="text-transform: none; font-family: opensanssemibold"
                >Update</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      rules: [],
      itemediting: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/exam/rules/view",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.rules = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    update() {
      var data = {};
      data["rule"] = this.rules.examRule;
      axios({
        url: "/exam/rules/edit",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>